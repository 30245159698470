<template>
  <div class="downloads-page">
    <v-row justify="center">
      <v-col md="6">
        <div class="download-options mx-5 mt-3">
          <v-text-field
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            solo
            v-model="search"
            label="Search"
          ></v-text-field>
          <v-row no-gutters class="mt-2">
            <v-col cols="6">
              <v-select
                v-model="filterFeature"
                :items="features"
                color="primary"
                label="Filter by"
                solo
                hide-details
                background-color="transparent"
                item-value="id"
                item-text="name"
                class="mt-0 rounded-input"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="sortBy"
                :items="['Newest', 'Oldest', 'Alphabetically']"
                color="primary"
                label="Sort by"
                solo
                hide-details
                background-color="transparent"
                item-value="id"
                item-text="name"
                class="mt-0 rounded-input"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-list v-if="filteredDownloads.length">
          <v-list-item
            v-for="(download, index) in filteredDownloads"
            :key="index"
            class="non-focused align-center justify-center"
            @click="openFile(download)"
          >
            <v-list-item-icon class="align-self-center">
              <v-icon>mdi-open-in-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{
                download.fileName.split(".").pop()
              }}</v-list-item-subtitle>
              <v-list-item-title>{{ download.fileName }}</v-list-item-title>
              <v-list-item-subtitle>
                <span class="mr-2">{{
                  moment(download.created).format("hh:mmA MMM d")
                }}</span>
                <v-chip x-small class="mr-2" color="green lighten-4">{{
                  download.feature
                }}</v-chip>
                <v-chip
                  v-if="download.subject"
                  x-small
                  color="blue lighten-4"
                  >{{ download.subject }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="align-self-center">
              <v-btn
                icon
                @click.stop="
                  toBeDeletedFile = download;
                  showSingleDeleteDialog = true;
                "
              >
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <h3 class="text-center mt-4" v-else>No downloads found</h3>
      </v-col>
    </v-row>
    <confirmation-dialog
      :visible="showSingleDeleteDialog"
      title="Are you sure you want to delete this download?"
      description="This action cannot be undone."
      @successCallback="deleteFile(toBeDeletedFile)"
      @failureCallback="showSingleDeleteDialog = false"
      delete-dialog
    />
    <confirmation-dialog
      :visible="showDeleteAllDialog"
      title="Are you sure you want to delete all downloads?"
      description="This action cannot be undone."
      @successCallback="clearDownloads"
      @failureCallback="showDeleteAllDialog = false"
      delete-dialog
    />
  </div>
</template>
<script>
import { Storage } from "@capacitor/storage";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { mapActions } from "vuex";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import { FileOpener } from "@ionic-native/file-opener";
import moment from "moment";
export default {
  name: "Downloads",
  data() {
    return {
      moment: moment,
      search: "",
      filterFeature: "All",
      features: ["All", "Homework", "Notice", "Messages", "Books"],
      sortBy: "Newest",
      downloads: [],
      showDeleteAllDialog: false,
      showSingleDeleteDialog: false,
      toBeDeletedFile: null,
    };
  },
  components: {
    ConfirmationDialog,
  },

  computed: {
    filteredDownloads() {
      if (!this.downloads.length) return this.downloads;
      var downloadsList = this.downloads.filter(
        (d) =>
          d.fileName.toLowerCase().includes(this.search.toLowerCase()) ||
          d.feature.toLowerCase().includes(this.search.toLowerCase())
      );
      if (this.filterFeature != "All" && this.filterFeature != null)
        downloadsList = downloadsList.filter(
          (d) => d.feature == this.filterFeature
        ); // feature filter
      console.log(this.sortBy);
      if (this.sortBy) {
        if (this.sortBy == "Alphabetically")
          downloadsList = downloadsList.sort((a, b) =>
            a.fileName.localeCompare(b.fileName)
          );
        else
          downloadsList = downloadsList.sort((a, b) =>
            this.sortBy == "Newest"
              ? new Date(b.created) - new Date(a.created)
              : new Date(a.created) - new Date(b.created)
          );
      }
      return downloadsList;
    },
  },

  methods: {
    ...mapActions([
      "setLoading",
      "setAppBarOptions",
      "showSnackbar",
      "resetAppBarOptions",
    ]),
    async openFile(download) {
      FileOpener.open(download.uri, download.type);
    },
    
    async deleteFile(download) {
      this.showSingleDeleteDialog = false;
      this.toBeDeletedFile = null;
      this.setLoading(true);
      // delete from file system
      Filesystem.deleteFile({
        path: download.path,
        directory: Directory.External,
      });

      // remove from list
      this.downloads = this.downloads.filter((d) => d !== download);
      //   save in local storage
      var downloadedFiles = (await Storage.get({ key: "downloadedFiles" }))
        .value;
      downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
      downloadedFiles = this.downloads;
      Storage.set({
        key: "downloadedFiles",
        value: JSON.stringify(downloadedFiles),
      });
      this.setLoading(false);
      this.showSnackbar({
        title: "Download has been deleted!",
        type: "success",
      });
    },
    async createAppBarOptions() {
      this.setAppBarOptions({
        actions: [
          {
            id: 1,
            title: "Clear Downloads",
            onClick: () => (this.showDeleteAllDialog = true),
            icon: "mdi-delete",
          },
        ],
      });
    },

    async clearDownloads() {
      this.showDeleteAllDialog = false;
      // clear downloads
      var downloadedFiles = (await Storage.get({ key: "downloadedFiles" }))
        .value;
      downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
      downloadedFiles.map((df) => {
        Filesystem.deleteFile({ path: df.path, directory: Directory.External });
      });
      // clear list
      this.downloads = [];
      // store in downloads
      Storage.set({ key: "downloadedFiles", value: this.downloads });
      this.showSnackbar({
        title: "All downloads deleted!",
        text: "All files downloaded inside the app have been cleared.",
        type: "success",
      });
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  async created() {
    var downloadedFiles = (await Storage.get({ key: "downloadedFiles" })).value;
    downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
    this.downloads = downloadedFiles;
    this.createAppBarOptions();
  },
};
</script>
<style scoped>
.word-break-normal {
  word-break: normal !important;
}
</style>
